<template>
  <div class="relative">
    <canvas
      ref="canvas"
      :style="{ height: height + 'px', width: width + 'px' }"
    />
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  props: {
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
		yellowBall: {
			type: Boolean,
			default: false
		},
    changeTestimonial: {},
    testimonials: {},
    currentSelected: {},
  },
  data() {
    return {
      project: null,
      ballPositions: [],
      handle_len_rate: 0.4,
      circlePaths: [],
      largeCircle: null,
      connections: null,
      gsapWatch: null,
    };
  },
  watch: {
    currentSelected(newVal) {
      this.gsapWatch?.kill();
      this.gsapWatch = null;
      if (!this.circlePaths[newVal]) {
        return;
      }

      this.gsapWatch = gsap.to(this.largeCircle.position, {
        duration: 0.6,
        x: this.circlePaths[newVal].position.x,
      });
    },
  },
  mounted() {
    // paper.install(window);
    paper.setup(this.$refs.canvas);

    gsap.ticker.fps(30);
    gsap.ticker.add((event) => {
      this.generateConnections(this.circlePaths);
    });

    const GRID_GAP = 30;
    for (let i = 0; i < this.testimonials.length; i++) {
      this.ballPositions.push([i + i * GRID_GAP + 30, 30]);
      // let x = this.startWidth;
      // while(x < this.$refs.canvas.clientWidth) {
      //   this.ballPositions.push([x, i + i*GRID_GAP])
      //   x += GRID_GAP;
      // }
    }

    this.initMetaball();
  },
  methods: {
    initMetaball() {
      this.project = paper.project;

      this.project.view.hitTest = (event) => {};
      // this.project.view.onMouseMove = this.onMouseMove;
      this.project.currentStyle = {
        // fillColor: '#FFC800',
      };

      for (let i = 0, l = this.ballPositions.length; i < l; i++) {
        const circlePath = new paper.Path.Circle({
          center: this.ballPositions[i],
          radius: 8,
        });
        circlePath.onClick = (event) => {
          this.changeTestimonial(event.target._index);
          this.gsapWatch?.kill();
          this.gsapWatch = null;
          this.gsapWatch = gsap.to(this.largeCircle.position, {
            duration: 0.6,
            x: event.target.position.x,
          });
        };
        circlePath.strokeColor = "#000000";
        circlePath.fillColor = "#FFFFFF";
        this.circlePaths.push(circlePath);
      }

      this.largeCircle = new paper.Path.Circle({
        center: [30, 30],
        radius: 12,
      });
      this.largeCircle.onClick = () => {};
      this.largeCircle.fillColor = "#000000";
      this.circlePaths.push(this.largeCircle);

      this.connections = new paper.Group();
      this.generateConnections(this.circlePaths);
    },
    onMouseMove(event) {
      const pointNoVerticalMove = event.point;
      if (event.point.y > 35 || event.point.y < 25) {
        pointNoVerticalMove.y = this.largeCircle.position.y;
      }
      // if(event.point.x / 30 > 6 || (event.point.x % 30) > 8) {
      //     pointNoVerticalMove.x = this.largeCircle.position.x
      // }

      this.largeCircle.position = event.point;

      this.generateConnections(this.circlePaths);
      // this.project.view.update();
    },
    generateConnections(paths) {
      this.connections.removeChildren();
      for (let i = 0, l = paths.length; i < l; i++) {
        for (let j = i - 1; j >= 0; j--) {
          const maxD = 30;
          // if (i <= this.currentSelected) {
          //     maxD = 31
          // }
          const path = this.metaball(paths[i], paths[j], 0.5, 2.4, maxD, j);
          if (path) {
            this.connections.appendTop(path);
            // path.removeOnMove();
          }
        }
      }
    },
    metaball(ball1, ball2, v, handle_len_rate, maxDistance, j) {
      const center1 = ball1.position;
      const center2 = ball2.position;
      let radius1 = ball1.bounds.width / 2;
      let radius2 = ball2.bounds.width / 2;
      const pi2 = Math.PI / 2;
      const d = center1.getDistance(center2);
      let u1, u2;

      if (radius1 === 0 || radius2 === 0) return;

      if (d > maxDistance || d <= Math.abs(radius1 - radius2)) {
        ball2.fillColor = this.$props.yellowBall ? "#ffc800" : '#FFFFFF';
        // if ( j <= this.currentSelected ) {
        //     ball2.fillColor = '#000000'
        // }
        return;
      } else if (d < radius1 + radius2) {
        u1 = Math.acos(
          (radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d),
        );
        u2 = Math.acos(
          (radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d),
        );
      } else {
        u1 = 0;
        u2 = 0;
      }

      ball2.fillColor = "#000000";
      const angle1 = center2.subtract(center1).getAngleInRadians();
      const angle2 = Math.acos((radius1 - radius2) / d);
      const angle1a = angle1 + u1 + (angle2 - u1) * v;
      const angle1b = angle1 - u1 - (angle2 - u1) * v;
      const angle2a = angle1 + Math.PI - u2 - (Math.PI - u2 - angle2) * v;
      const angle2b = angle1 - Math.PI + u2 + (Math.PI - u2 - angle2) * v;
      const p1a = center1.add(this.getVector(angle1a, radius1));
      const p1b = center1.add(this.getVector(angle1b, radius1));
      const p2a = center2.add(this.getVector(angle2a, radius2));
      const p2b = center2.add(this.getVector(angle2b, radius2));

      const totalRadius = radius1 + radius2;
      let d2 = Math.min(
        v * handle_len_rate,
        p1a.subtract(p2a).length / totalRadius,
      );

      d2 *= Math.min(1, (d * 2) / (radius1 + radius2));

      radius1 *= d2;
      radius2 *= d2;

      const path = new paper.Path({
        segments: [p1a, p2a, p2b, p1b],
        style: ball2.style,
        closed: false,
      });
      const segments = path.segments;

      segments[0].handleOut = this.getVector(angle1a - pi2, radius1);
      segments[1].handleIn = this.getVector(angle2a + pi2, radius2);
      segments[2].handleOut = this.getVector(angle2b - pi2, radius2);
      segments[3].handleIn = this.getVector(angle1b + pi2, radius1);
      return path;
      // ... same function as in original script ...
    },
    getVector(radians, length) {
      return new paper.Point({
        angle: (radians * 180) / Math.PI,
        length,
      });
      // ... same function as in original script ...
    },
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  /*height: 700px;*/
  display: block;
  background-color: transparent;
  cursor: pointer;
}
</style>
